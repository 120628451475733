<template>
<div class="vendors">
  <div class="page-header">
    <h1>{{ $t('Inventory Management') }} > {{ $t('Ordering Records')}} </h1>
  </div>
  <div class="page-content">
    <div class="content-header">
      <h2>{{ $t('Ordering Records') }}</h2>
    </div>
    <div class="promotions-list">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ $t('ID') }}</th>
            <th scope="col">{{ $t('Created Date') }}</th>
            <th scope="col">{{ $t('Vendor Name') }}</th>
            <th scope="col">{{ $t('Total') }}</th>
            <th scope="col">{{ $t('Detail') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>5050293402</td>
            <td>2023-10-22 12:00:00</td>
            <td>海龍食品供應公司</td>
            <td>HKD $5000</td>
            <td>
              <div class="action-links">
                <a class="action-link" href="#">
                  {{ $t('View') }}
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';

export default {
name: 'Vendors',
components: {
  ItemFilter,
},
methods: {
  formatDateTime(timestamp, format){
    return Common.formatDateTime(timestamp, format);
  },
},
data(){
  return {
    activeName: 'All',
    displayCreateForm: false,
    displayUpdateForm: false,
    vendors: [],
    orignalVendors: [],
  }
},
computed: mapState({
 apiUrl: state => state.apiUrl,
}),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
